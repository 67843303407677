const BarChartData = [
  {
    name: '1996',
    dl: 12087500,
  },
  {
    name: '1997',
    dl: 1687500,
  },
  {
    name: '1998',
    dl: 3437500,
  },
  {
    name: '1999',
    dl: 5604524,
  },
  {
    name: '2000',
    dl: 4767139,
  },
  {
    name: '2001',
    dl: 2039652,
  },
  {
    name: '2002',
    dl: 2994029,
  },
  {
    name: '2003',
    dl:744598,
  },
  {
    name: '2004',
    dl: 7009402,
  },
  {
    name: '2005',
    dl: 10114757,
  },
  {
    name: '2006',
    dl: 3875338,
  },
  {
    name: '2007',
    dl: 5061120,
  },
  {
    name: '2008',
    dl: 6252187,
  },
  {
    name: '2009',
    dl: 7561530,
  },
  {
    name: '2010',
    dl: 25510784,
  },
  {
    name: '2011',
    dl: 27934566,
  },
  {
    name: '2012',
    dl: 27943566,
  },
  {
    name: '2013',
    dl: 22812513,
  },
  {
    name: '2014',
    dl: 40270808,
  },
  {
    name: '2015',
    dl: 35864146,
  },
  {
    name: '2016',
    dl: 33954551,
  },
  {
    name: '2017',
    dl: 33084556,
  },
  {
    name: '2018',
    dl: 25888228,
  },
  {
    name: '2019',
    dl: 48424492,
  },
  {
    name: '2020',
    dl: 38146857,
  },
];

export default BarChartData;
